<template lang="">
    <div>
        <New/>
    </div>
</template>
<script>
export default {
    name:"new-property",
    components: {
    New: () => import('@/components/property/New.vue'),
}
}
</script>
